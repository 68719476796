import React, { useEffect } from 'react';
import ConfidentialSVG from '../../assets/Confidential.svg';
import FreeSVG from '../../assets/Free.svg';
import VettedSVG from '../../assets/Vetted.svg';
import { useConfiguration } from '../../context/configurationContext';
import SignupForm from '../LandingPage/SignupForm/SignupForm';
import { useAnimatedInView } from './AboutPage.shared';

import debounce from 'lodash/debounce';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import { emailTaken } from '../../ducks/auth.duck';
import { createResourceLocatorString } from '../../util/routes';
import { generateRandomUUID } from '../../util/uuid';
import { emailFormatValidWithoutForm } from '../../util/validators';
import { saveProfileDataToLocalStorage as saveCompanyProfileDataToLocalStorage } from '../CreateCompanyPage/CreateCompanyPage.duck';
import {
  saveProfileDataToLocalStorage as saveTalentProfileDataToLocalStorage,
  updateorcreateMailChimpMember,
  uploadFiletoS3,
} from '../CreateTalentPage/CreateTalentPage.duck';
import css from './AboutPage.module.css';

export const SectionHome = props => {
  const { sectionId, isMobileLayout, errors, isEmailTaken, initialValues, setLoadingPage } = props;

  const { ref, AnimatedDiv } = useAnimatedInView({
    animate: !isMobileLayout,
    threshold: 0.7,
    triggerOnce: true,
  });

  const DEBOUNCE_WAIT_TIME = 200;
  const config = useConfiguration();
  const dispatch = useDispatch();
  const history = useHistory();
  const routes = useRouteConfiguration();
  const handleSubmit = values => {
    const { email } = values;

    if (emailFormatValidWithoutForm(email)) {
      dispatch(emailTaken(values));
    }
  };

  const debouncedSubmit = debounce(handleSubmit, DEBOUNCE_WAIT_TIME, {
    leading: false,
    trailing: true,
  });

  const handleChangeWithDebounce = values => {
    return debouncedSubmit(values);
  };

  const { email } = initialValues || {};
  useEffect(() => {
    if (email && email != undefined) {
      debouncedSubmit({
        email: email,
      });
    }
  }, []);

  return (
    <div id={sectionId}>
      <div className={css.mainContent}>
        <div className={css.leftContent}>
          <div className={css.mainTitle}>
            <FormattedMessage id="LandingPage.SectionHome.mainTitle" />
          </div>
          <div className={css.mobileMap}>
            <div className={css.mainDescription}>
              <FormattedMessage id="LandingPage.SectionHome.mainDescription" />
            </div>
            <div className={css.mainDescription2}>
              {' '}
              <FormattedMessage id="LandingPage.SectionHome.mainDescriptionTwo" />
            </div>
          </div>

          <div className={css.iconDesktopContent}>
            <div className={css.svgContent}>
              <img alt="" src={VettedSVG} />
              <div>
                {' '}
                <FormattedMessage id="LandingPage.SectionHome.VettedSVGTitle" />
              </div>
            </div>
            <div className={css.svgContent}>
              <img alt="" src={ConfidentialSVG} />
              <div>
                {' '}
                <FormattedMessage id="LandingPage.SectionHome.ConfidentialSVGTitle" />
              </div>
            </div>
            <div className={css.svgContent}>
              <img alt="" src={FreeSVG} />
              <div>
                {' '}
                <FormattedMessage id="LandingPage.SectionHome.FreeSVGTitle" />
              </div>
            </div>
          </div>
        </div>
        <div className={css.formContent}>
          <div className={css.formContentTitle}>
            <FormattedMessage id="LandingPage.SectionHome.formContentTitle" />
          </div>
          <SignupForm
            className={css.form}
            saveActionMsg="Submit"
            initialValues={initialValues}
            onSubmit={async values => {
              const {
                id,
                firstName,
                lastName,
                email,
                name,
                registerType,
                file,
                linkedinURL,
              } = values;

              setLoadingPage(true);

              if (registerType === 'candidate') {
                if (file) {
                  Promise.all([dispatch(uploadFiletoS3(file))]).then(response => {
                    const { status, content, s3URL } = response[0];
                    const {
                      basic_info,
                      current_work,
                      specifics,
                      skills_and_styles,
                      finishing_touches,
                    } = content || {};

                    if (status !== 'FAILED') {
                      const openTo =
                        finishing_touches.seeking_full_time && finishing_touches.seeking_part_time
                          ? 'both'
                          : finishing_touches.seeking_full_time
                          ? 'full-time'
                          : finishing_touches.seeking_part_time
                          ? 'part-time'
                          : null;
                      let updateValues = {
                        id: id ? id : generateRandomUUID(),
                        // firstName: basic_info.first_name,
                        // lastName: basic_info.last_name,
                        // email: basic_info.email,
                        name,
                        registerType,
                        phone: basic_info.phone,
                        birthday: basic_info.date_of_birth,
                        primarySpecialty: current_work.primary_specialty,
                        secondarySpecialty: current_work.secondary_specialties,
                        expertise: current_work.expertise,
                        industry: current_work.current_position,
                        location: current_work.current_location,
                        title: specifics.current_position,
                        company: specifics.current_company,
                        university: specifics.university,
                        degree: specifics.degree,
                        graduationYear: [specifics.education_graduation_year],
                        experience: [specifics.experience_level],
                        salaryRequirements: specifics.desired_salary,
                        licensesAndCertification: specifics.licenses,
                        program: skills_and_styles.skills,
                        style: skills_and_styles.design_styles,
                        customStyles: skills_and_styles.custom_styles,
                        availability: finishing_touches.availability_status,
                        workplacePreference: finishing_touches.workplace_preference,
                        s3URL,
                      };
                      if (openTo !== null && openTo) updateValues.openTo = openTo;

                      const createMailChimpObject = {
                        firstName: updateValues.firstName,
                        lastName: updateValues.lastName,
                        email: updateValues.email,
                        step: 0,
                        registertype: 'Candidates',
                      };

                      Promise.all([
                        dispatch(saveTalentProfileDataToLocalStorage(updateValues)),
                        dispatch(updateorcreateMailChimpMember(createMailChimpObject)),
                      ]);

                      history.push(
                        createResourceLocatorString('NewTalentPage', routes, updateValues, {})
                      );
                    } else {
                      const updateValues = {
                        id: id ? id : generateRandomUUID(),
                        firstName,
                        lastName,
                        email,
                        name,
                        registerType,
                      };
                      const createMailChimpObject = {
                        firstName: firstName,
                        lastName: lastName,
                        email: email,
                        step: 0,
                        registertype: 'Candidates',
                      };

                      Promise.all([
                        dispatch(saveTalentProfileDataToLocalStorage(updateValues)),
                        dispatch(updateorcreateMailChimpMember(createMailChimpObject)),
                      ]);
                      history.push(
                        createResourceLocatorString('NewTalentPage', routes, updateValues, {})
                      );
                    }
                  });
                } else {
                  const updateValues = {
                    id: id ? id : generateRandomUUID(),
                    firstName,
                    lastName,
                    email,
                    name,
                    registerType,
                  };
                  const createMailChimpObject = {
                    firstName: firstName,
                    lastName: lastName,
                    email: email,
                    step: 0,
                    registertype: 'Candidates',
                  };
                  Promise.all([
                    dispatch(updateorcreateMailChimpMember(createMailChimpObject)),
                    dispatch(saveTalentProfileDataToLocalStorage(updateValues)),
                  ]);
                  history.push(
                    createResourceLocatorString('NewTalentPage', routes, updateValues, {})
                  );
                }
              } else {
                const updateValues = {
                  id: id ? id : generateRandomUUID(),
                  firstName,
                  lastName,
                  email,
                  name,
                  registerType,
                };
                const createMailChimpObject = {
                  firstName: name,
                  email: email,
                  step: 0,
                  registertype: 'Clients',
                };
                Promise.all([
                  dispatch(updateorcreateMailChimpMember(createMailChimpObject)),
                  dispatch(saveCompanyProfileDataToLocalStorage(updateValues)),
                ]);
                history.push(
                  createResourceLocatorString('NewCompanyPage', routes, updateValues, {})
                );
              }
            }}
            marketplaceCurrency={config.currency}
            onEmailChangeWithDebounce={handleChangeWithDebounce}
            isEmailTaken={isEmailTaken}
            fetchErrors={errors ?? {}}
            autoFocus
          />
        </div>
      </div>
    </div>
  );
};

export default SectionHome;
