import classNames from 'classnames';
import { bool, func, number, object, shape, string } from 'prop-types';
import React, { Component } from 'react';
import { Field } from 'react-final-form';
import deleteSVG from '../../assets/delete.svg'; // Import delete icon SVG
import { ValidationError } from '../../components';

import css from './FieldFileUpload.module.css';

const CONTENT_MAX_SIZE = 10 * 1024 * 1024; // 10 MB max file size (adjust as needed)

const FieldFileUploadComponent = props => {
  const {
    rootClassName,
    className,
    inputRootClass,
    customErrorText,
    id,
    label,
    placeholder,
    input,
    meta,
    onUnmount,
    isUncontrolled,
    inputRef,
    hideErrorMessage,
    inputClassName,
    customLabelInput,
    maxSize,
    accept,
    ...rest
  } = props;

  const [errorMessage, setErrorMessage] = React.useState(null);
  const { valid, invalid, touched, error } = meta;

  const hasError = !!customErrorText || !!(touched && invalid && (error || errorMessage));

  if (label && !id) {
    throw new Error('id required when a label is given');
  }
  const errorText = customErrorText || error;

  // Error message and input error styles are only shown if the
  // field has been touched and validation has failed.
  const fieldMeta = { touched: hasError, error: errorText };

  // Uncontrolled input uses defaultValue instead of value.
  const { value: defaultValue, ...inputWithoutValue } = input;
  const refMaybe = inputRef ? { ref: inputRef } : {};

  const inputClasses =
    inputRootClass ||
    classNames(
      css.input,
      {
        [css.inputSuccess]: valid,
        [css.inputError]: hasError,
      },
      inputClassName
    );

  const [fileName, setFileName] = React.useState(input.value ? input.value.name : null);

  const handleFileChange = event => {
    const file = event.target.files[0];
    setErrorMessage(null); // Clear the error message at the start

    if (file) {
      if (file.size > (maxSize || CONTENT_MAX_SIZE)) {
        input.onChange(null); // Clear the input value
        setFileName(null); // Clear the file name
        setErrorMessage('File is too large. Please upload a smaller file.'); // Set error message
      } else {
        input.onChange(file); // Set the selected file
        setFileName(file.name); // Update file name
      }
    } else {
      input.onChange(null); // Clear file if no file selected
      setFileName(null); // Clear the file name
    }
  };

  const handleRemoveFile = () => {
    input.onChange(null); // Clear the file value
    setFileName(null); // Reset the file name
  };

  const inputProps = isUncontrolled
    ? {
        className: inputClasses,
        id,
        type: 'file',
        defaultValue,
        placeholder: placeholder || label,
        accept,

        style: { display: 'none' }, // Add the style here
        ...refMaybe,
        ...inputWithoutValue,
        onChange: handleFileChange,
        ...rest,
      }
    : {
        className: inputClasses,
        id,
        type: 'file',
        placeholder: placeholder || label,
        accept,
        style: { display: 'none' },
        ...refMaybe,
        onChange: handleFileChange,
        ...rest,
      };

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <input {...inputProps} />
      {/* Custom upload button */}
      <label
        htmlFor={id}
        className={fileName?css.customButtonDisabled:css.customButton}
        disable
      >
        {customLabelInput || 'Upload File'}
      </label>
      {fileName && (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span>{fileName}</span>
          <button
            onClick={handleRemoveFile}
            style={{
              marginLeft: '10px',
              cursor: 'pointer',
              border: 'none',
              background: 'none',
            }}
          >
            <img src={deleteSVG} alt="Delete file" />
          </button>
        </div>
      )}
      {hideErrorMessage ? null : <ValidationError fieldMeta={fieldMeta} />}
    </div>
  );
};

FieldFileUploadComponent.defaultProps = {
  rootClassName: null,
  className: null,
  inputRootClass: null,
  onUnmount: null,
  customErrorText: null,
  id: null,
  label: null,
  placeholder: null,
  isUncontrolled: false,
  inputRef: null,
  maxSize: CONTENT_MAX_SIZE,
  accept: null,
};

FieldFileUploadComponent.propTypes = {
  rootClassName: string,
  className: string,
  inputRootClass: string,

  onUnmount: func,

  customErrorText: string,

  id: string,
  label: string,
  placeholder: string,

  isUncontrolled: bool,
  inputRef: object,

  input: shape({
    onChange: func.isRequired,
    type: string.isRequired,
  }).isRequired,
  meta: object.isRequired,
  inputClassName: string,
  customLabelInput: string,

  maxSize: number,
  accept: string,
};

class FieldFileUpload extends Component {
  componentWillUnmount() {
    if (this.props.onUnmount) {
      this.props.onUnmount();
    }
  }

  render() {
    return <Field component={FieldFileUploadComponent} {...this.props} />;
  }
}

export default FieldFileUpload;
